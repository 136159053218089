<template>
  <div class="dropdown">
    <b-form-input
      id="drop-input"
      autocomplete="off"
      @input="searchLoc"
      :placeholder="i18n['custome-location-search'].tcSearch"
      :state="state"
      v-model="selectedValue"
    ></b-form-input>
    <div id="myDropdown" class="dropdown-content" v-show="true">
      <b-list-group v-show="isSearching" v-if="result.length > 0">
        <b-list-group-item button v-for="(item, index) in result" :key="index" @click="selectedItem(item)">
          {{ item.org_name }}
          <br />
          <small>{{ item.adr_line1 }}</small>, <small>{{ item.adr_city }}</small>
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-show="loading">
        <b-list-group-item>
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-show="notfound">
        <b-list-group-item>{{ i18n['custome-location-search'].tcNoMatchFound }}</b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { debounce } from 'debounce'
import { CALENDAR_API_CONFIG } from '@/config/calendar.api.config'

export default {
  name: 'custome-location-search',
  props: {
    defaultvalue: {
      type: String,
      default: null,
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'custome-location-search': {
            tcNoMatchFound: 'No match found',
            tcSearch: 'Search..',
          },
        }
      },
    },
  },

  data() {
    return {
      result: [],
      selected: false,
      state: false,
      isSearching: false,
      notfound: false,
      loading: false,
      selectedValue: null,
    }
  },
  methods: {
    getvalue(item) {
      this.selected = true
      this.isSearching = false
      this.state = false
      this.$emit('input', item)
    },
    searchLoc: debounce(function ($event) {
      this.state = false
      if ($event === '') return
      this.isSearching = true
      this.notfound = false
      this.loading = true
      this.result = []
      axios
        .get(CALENDAR_API_CONFIG.getEventLocation($event))
        .then((res) => {
          this.result = res.data
          if (res.data.length === 0) {
            this.notfound = true
            this.loading = false
          } else {
            this.loading = false
            this.notfound = false
          }
        })
        .catch((err) => {})
        .finally(() => {})
    }, 1000),

    selectedItem(item) {
      this.selectedValue = item.org_name
      this.notfound = false
      this.loading = false
      this.isSearching = false
      this.state = true
      this.$emit('input', item)
    },
  },
  watch: {
    defaultvalue: function (value, oldvalue) {
      this.selectedValue = value
    },
  },
  created() {
    if (this.$props.defaultvalue !== null) {
      this.selectedValue = this.$props.defaultvalue
      this.state = true
    }
  },
}
</script>
<style scoped>
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

#myInput {
  /* box-sizing: border-box; */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#myInput:focus {
  outline: 3px solid #ddd;
}

.dropdown {
  position: relative;
  display: inline-block;
}
#drop-input {
  width: 340px;
}
@media (min-width:360px) and (max-width:640px){
  #drop-input {
    width: auto !important;
  }
  .dropdown-content{
    width: auto !important;
  }
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
  width: 340px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 9999999;
  max-height: 300px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}
</style>